import React from "react";
import Page from "../components/Page";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../static/learning.svg"
import BlogCard from "../components/Blog/BlogCard"
import Featured from "../components/Cards/Featured";


export default function Index({ data }) {
  const { posts } = data.blog;

  return (
    <>
      <Page
        title={"Skolappar och hjälpmedel för dina studier"}
        description="Vi samlar alla skolappar och hjälpmedel för elever och lärare på ett och samma ställe."
      >
        <div className=" bg-gray-100 ">
          <div className="container px-5 py-10 mx-auto">
            <div className="hero-wrapper grid grid-cols-1 md:grid-cols-12 gap-8 items-center">

              <div className="hero-text col-span-6">
                <h1 className=" font-bold text-4xl md:text-5xl max-w-xl text-gray-900 leading-tight">Hitta nya skolappar</h1>
                <hr className=" w-12 h-1 bg-emerald-500 rounded-full mt-8" />
                <p className="text-gray-800 text-base leading-relaxed mt-8 font-semibold">Vi samlar alla skolappar och hjälpmedel för elever och lärare på ett och samma ställe.</p>
                <div className="flex space-x-2 mt-6 justify-start">
                  <Link to="/blogg/"><button type="button" className="text-white bg-gradient-to-r from-emerald-500 via-emerald-600 to-emerald-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-emerald-300  font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Läs artiklar</button></Link>
                  <Link to={"/recensioner/"}><button type="button" className="text-emerald-500 hover:text-white border border-emerald-700 hover:bg-emerald-800 focus:ring-4 focus:outline-none focus:ring-emerald-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Visa apparna</button></Link>
                </div>
              </div>
              <div className="hero-image col-span-6">
                <img src={Hero} alt="hero" />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-10 px-5  mx-auto ">
          <section className="bg-white  mb-8">
            <div className="gap-16 items-center mx-auto max-w-screen-xl lg:grid lg:grid-cols-2">
              <div className="font-light text-gray-500 sm:text-lg ">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                  Appar för dina studier
                </h2>
                <p className="mb-4">
                  Det kan vara en utmaning att hitta rätt appar för dina studier och skolan. Det finns så många olika alternativ att det kan vara svårt att veta var man ska börja.


                </p>
                <p className="mb-4">
                  För att göra det enklare samlar vi alla de bästa apparna för skolan och studierna här på vår sida. Oavsett om du behöver hjälp med läxorna, ta anteckningar eller något annat så vi har allt för att du ska kunna lyckas med dina studier.


                </p>
                <p>
                  Kolla in vårt urval av appar för skola och studier för att hitta den perfekta appen för dig och dina behov.


                </p>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-8">
                <GatsbyImage
                  image={data.resource1.childImageSharp.gatsbyImageData}
                  backgroundColor="white"
                  alt="appar för studier"
                  loading="eager"
                  className="w-full rounded-lg mb-4 lg:mb-10"
                />
                <GatsbyImage
                  image={data.resource2.childImageSharp.gatsbyImageData}
                  backgroundColor="white"
                  alt="appar för studier"
                  loading="eager"
                  className="mt-4 w-full lg:mt-10 rounded-lg"
                />
              </div>
            </div>
          </section>
        </div>
        <div className="bg-gray-50">
        <div className="container my-10 px-5 mx-auto ">
              <div class="py-8  mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div class="mx-auto ">
                  <h2 class="mb-8 text-3xl lg:text-4xl text-center tracking-tight font-extrabold text-gray-900">
                    Utvalda favoriter
                  </h2>
                  <div className="grid gap-8 lg:grid-cols-2">
                  <Featured image={data.zcooly.childImageSharp.gatsbyImageData} title={"Zcooly"} link={"/recensioner/zcooly/"} affLink={"https://zcooly.se/"} description={"Zcooly är ett spel för att lära barn i åldern 5-12 år matematik! Det är visuellt och interaktivt sätt att lära sig, och det är dessutom riktigt roligt för barnet, vilket gör barnets skärmtid mer meningsfull."}/>
                  <Featured image={data.albert.childImageSharp.gatsbyImageData} title={"Albert"} link={"/recensioner/hejalbert/"} affLink={"https://hejalbert.se/"} description="Albert är en onlineplattform med flera appar som gör lärandet mer meningsfull. På deras plattform kan du förbättra kunskaperna i matematik, programmering, läsning och geografi."/>
                  <Featured image={data.duolingo.childImageSharp.gatsbyImageData} title={"Duolingo"} link={"/appar/doulingo/"} affLink={"https://www.duolingo.com/"} description="Duolingo är en app och plattform för språkinlärning som erbjuder kurser i över 30 olika språk. Doulingo är ett roligt och effektivt sätt att lära dig ett nytt språk."/>
                  <Featured image={data.jaramba.childImageSharp.gatsbyImageData} title={"Jaramba"} link={"/recensioner/jaramba/"} affLink={"https://www.jaramba.se/"} description={"Jaramba är en pedagogisk app för barn i förskoleåldern. Appen hjälper barnet att bygga upp sitt ordförråd samtidigt som barnet har roligt."}/>

                  </div>
           
            </div>
            </div>
        </div>
        </div>
     

        <div className="container my-10 px-5 mx-auto ">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Senaste från bloggen</h2>
          <div className="blog-grid">
            {posts && posts.map((post) => {
              return <BlogCard key={post.id} title={post.frontmatter.title} description={post.excerpt} link={post.fields.slug} image={post.frontmatter?.coverImage} />
            })}
          </div>
        </div>


        <br />
        <br />

      </Page>
    </>
  );
}

export const pageQuery = graphql`
  query home {
    homeTop: file(relativePath: { eq: "undraw_Savings_re_eq4w.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    resource1: file(relativePath: { eq: "resource1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    resource2: file(relativePath: { eq: "resource2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    duolingo: file(relativePath: { eq: "doulingo.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    albert: file(relativePath: { eq: "albert.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    zcooly: file(relativePath: { eq: "zcooly.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    jaramba: file(relativePath: { eq: "jaramba.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    blog: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { fileAbsolutePath: { regex: "/(/blog/)/" } }
      limit: 3,
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
        id
        excerpt(pruneLength: 120)
      }
    }
  }
  
`;