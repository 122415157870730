import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Featured({ title, description, image, link, affLink, }) {
    return (
        <div className="sm:flex bg-white border border-gray-100 shadow-lg rounded-lg overflow-hidden max-h-full ">
            <GatsbyImage image={image}
                backgroundColor="white"
                className="hidden sm:flex w-1/3 bg-cover"
                imgClassName="hidden sm:flex" />
       
            <div className="w-full sm:w-2/3 p-4">
                <h1 className="text-gray-900 font-bold text-2xl">{title}</h1>
                <p className="mt-2 text-gray-600 text-md">{description}</p>
                <div className="flex item-center mt-2">
                    <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                        <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                    </svg>
                    <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                        <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                    </svg>
                    <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                        <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                    </svg>
                    <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                        <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                    </svg>
                    <svg className="w-5 h-5 fill-current text-gray-700" viewBox="0 0 24 24">
                        <path d="M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.63L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z" />
                    </svg>
                </div>
                <div className="mt-3">
                    <Link to={link} className="px-3 py-2 bg-emerald-600 hover:bg-emerald-700 text-white text-xs font-bold uppercase rounded mt-3">Läs Recension</Link>
                    <a href={affLink} rel="nofollow" target="_blank" className="px-3 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded mt-3 ml-3">Gå till App</a>

                </div>
            </div>
        </div>
    );
}
